import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { mediaBreakpointDown, mediaBreakpointUp } from 'utils/styledCssHelpers';
import DotSVG from 'components/DotSVG';
import DashboardOverview from 'images/svg/dashboard-overview.svg';

export const SSection = styled.section`
  margin: ${props => props.margin || '50px 0'};
  padding: ${props =>
    props.$containerPadding
      ? props.$containerPadding
      : props.padding
      ? '3em 0'
      : 'unset'};
  position: relative;
  overflow: ${props => (props.$overflow ? 'hidden' : 'unset')};
  z-index: 0;
  background: ${props =>
    props.mode
      ? (props.mode == 'dark' && props.theme.colors.main) ||
        (props.mode == 'white' && props.theme.colors.white)
      : props.theme.colors[props.$background || 'bg_grey']};

  ${mediaBreakpointUp(
    'lg',
    `
  .padding-top {
    padding-top: 195px;
  }
  `,
  )}
`;

export const SCol = styled(Col)`
  position: ${props => props.position || 'relative'}; ;
`;

export const SOverlayImage = styled.div`
  position: absolute;
  bottom: ${props => (props.$reducedPlacement ? '-20px' : '-90px')};
  right: ${props => (props.$reducedPlacement ? '-40px' : '-70px')};

  display: block;
  height: 300px;
  width: 355px;
  z-index: 120;
`;

export const SOverlayStarSVG = styled(DotSVG)`
  height: 550px;

  width: 30%;
  top: 137px;
  z-index: 100;
  svg {
    width: 505px;
  }
  ${mediaBreakpointDown(
    'lg',
    `
    height: 500px;
    width: 40%;
    bottom: unset;
    top: 531px;
  `,
  )}
  ${mediaBreakpointDown(
    'md',
    `
  height: 355px;
  width: 40%;
  bottom: 137px;
  top: unset;
  `,
  )}
`;
export const SDashboardOverviewSVG = styled(DashboardOverview)``;
