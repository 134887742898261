/**
 *
 * SplitSectionWithImage
 *
 */

import React from 'react';
import {
  SCol,
  SSection,
  SOverlayImage,
  SOverlayStarSVG,
  SDashboardOverviewSVG,
} from './styles';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import GracefulImage from 'components/GracefulImage';
import CircleShape from 'components/Shapes/Circle/index';
import DotSVG from 'components/DotSVG';

const SplitSectionWithImage = ({
  imageName,
  imageAlt,
  isImageLeft,
  isImageVisibleMobile = true,
  children,
  hasCircleBg,
  margin,
  displayStarSvg = true,
  mode,
  imageContainerPosition,
  starSvgMoveY,
  starSvgPositionLeft,
  starSvgPositionTop,
  starSvgPositionRight,
  starSvgPositionBottom,
  starSvgWidth,
  starSvgFill,
  starSvgIsVisibleMobile,
  isStarSvgSmall,
  padding = true,
  $overflow = true,
  $imageTop = 0,
  padding_top = false,
  alignChildren = true,
  starSvgMoveLeft,
  starSvgZInxdex,
  background,

  showOverlayImage = false,
  showOverlaySVG = false,
  overlayImage = '',
  containerPadding,
  imageData = null,
  useGracefulImage = false,
}) => {
  const firstColOrder = isImageLeft ? 'order-md-0 mt-md-0 mt-3' : 'order-md-5';
  const secondColOrder = isImageLeft ? 'order-md-5' : 'order-md-0 mb-md-0 mb-3';
  const paddingTop = padding_top ? 'padding-top' : '';
  return (
    <>
      <SSection
        $overflow={$overflow}
        $background={background}
        $containerPadding={containerPadding}
        padding={padding}
        margin={margin}
        mode={mode}
      >
        {hasCircleBg && <CircleShape mode={mode} />}
        {showOverlaySVG && (
          <SOverlayStarSVG
            right
            top
            $moveY="0"
            isSVGLine={true}
            isVisibleMobile={true}
          />
        )}
        <Container>
          <Row>
            <SCol
              xs="12"
              md="6"
              className={`align-self-center order-5 ${firstColOrder}`}
              position={imageContainerPosition}
              $imageTop={$imageTop}
            >
              {displayStarSvg && (
                <DotSVG
                  $moveY={starSvgMoveY}
                  moveLeft={starSvgMoveLeft}
                  indexZ={starSvgZInxdex}
                  top={starSvgPositionTop}
                  left={starSvgPositionLeft}
                  bottom={starSvgPositionBottom}
                  right={starSvgPositionRight}
                  width={starSvgWidth}
                  fill={starSvgFill}
                  isVisibleMobile={starSvgIsVisibleMobile}
                  isSVGLine={isStarSvgSmall}
                />
              )}
              {showOverlayImage && (
                <SOverlayImage $reducedPlacement={!!overlayImage}>
                  {overlayImage ? (
                    <GracefulImage
                      filename={overlayImage}
                      alt={'combined content overlay'}
                    />
                  ) : (
                    <SDashboardOverviewSVG />
                  )}
                </SOverlayImage>
              )}
              <GracefulImage
                className={`${
                  isImageVisibleMobile ? '' : 'd-none'
                } d-sm-none d-md-block`}
                styles={{ top: $imageTop && $imageTop }}
                filename={imageName}
                alt={imageAlt}
                imageData={imageData}
                useDeprecatedImage={!useGracefulImage}
              />
            </SCol>
            <Col
              xs="12"
              md="6"
              className={`${
                alignChildren ? 'align-self-center ' : ''
              }order-0 ${secondColOrder} ${paddingTop}`}
            >
              {children}
            </Col>
          </Row>
        </Container>
      </SSection>
    </>
  );
};

SplitSectionWithImage.propTypes = {
  alignChildren: PropTypes.bool,
  imageName: PropTypes.string,
  imageAlt: PropTypes.string,
  isImageLeft: PropTypes.bool,
  isImageVisibleMobile: PropTypes.bool,
  children: PropTypes.node,
  margin: PropTypes.string,
  // Determine whether to show circled svg background
  hasCircleBg: PropTypes.bool,
  // Set the display of the dotsvg (default -> false='none' OR true='block')
  displayStarSvg: PropTypes.bool,
  // Set the mode of the section.
  // This will affect the CircleShape background and text colour contained within the section (default -> light OR 'dark')
  mode: PropTypes.string,
  // Determine the position of image column. This is useful in cases when you want
  // an element to escape this container while using position absolute. (default -> relative OR static)
  imageContainerPosition: PropTypes.string,
  // translateY of dot svg (default -> -30px)
  starSvgMoveY: PropTypes.string,
  // set left position of DotSvg to 0 or unset
  starSvgPositionLeft: PropTypes.bool,
  // set top position of DotSvg to 0 or unset
  starSvgPositionTop: PropTypes.bool,
  // set right position of DotSvg to 0 or unset
  starSvgPositionRight: PropTypes.string,
  // set bottom position of DotSvg to 0 or unset
  starSvgPositionBottom: PropTypes.string,
  // change width of DotSvg with respect to the parent container
  starSvgWidth: PropTypes.string,
  // change fill colour of DotSvg (default -> accent red, main, pastel_blue)
  starSvgFill: PropTypes.string,
  // determine visibility of DotSvg on mobile devices (default -> false)
  starSvgIsVisibleMobile: PropTypes.bool,
  // determine whether to display smaller version of DotSvg or bigger (default -> DotSvgBig)
  isStarSvgSmall: PropTypes.bool,

  padding: PropTypes.bool,

  padding_top: PropTypes.bool,

  $overflow: PropTypes.bool,

  $imageTop: PropTypes.number,

  starSvgMoveLeft: PropTypes.string,
  starSvgZInxdex: PropTypes.number,

  showOverlayImage: PropTypes.bool,
  showOverlaySVG: PropTypes.bool,
  overlayImage: PropTypes.string,
  background: PropTypes.string,
  containerPadding: PropTypes.string,
  useGracefulImage: PropTypes.bool,
  imageData: PropTypes.object,
};

export default SplitSectionWithImage;
