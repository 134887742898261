import React from 'react';
import PropTypes from 'prop-types';
import { SCircle } from './styles';

const CircleShape = ({ rotate, mode }) => {
  return <SCircle rotate={rotate} mode={mode}></SCircle>;
};

CircleShape.propTypes = {
  rotate: PropTypes.string,
  // This prop determines the background colour of this component
  // ( default -> circle will be light grey OR mode=dark circle will be primary)
  mode: PropTypes.string,
};

export default CircleShape;
1;
