import styled from 'styled-components';
import { mediaBreakpointUp } from 'utils/styledCssHelpers';
import DarkSvg from 'images/svg/circle-dark.svg';

export const SCircle = styled(DarkSvg)`
  transform: rotate(${props => props.rotate || '0'}) scale(0.25)
    translateX(-155%) translateY(-80%);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: none;
  fill: ${props =>
    props.mode ? props.mode === 'dark' && 'rgba(50,93,165,0.4)' : '#f9fafd'};

  ${mediaBreakpointUp(
    'md',
    `
    display: block;
  transform: scale(0.5) translateX(-65%) translateY(-15%)
  `,
  )};
  ${mediaBreakpointUp(
    'lg',
    `
  transform: scale(0.6) translateX(-50%)
  `,
  )};
  ${mediaBreakpointUp(
    'xl',
    `
    transform: scale(1) translateX(-13%) translateY(22%)
  `,
  )};
  ${mediaBreakpointUp(
    'xxl',
    `
    transform: scale(1.5) translateX(0) translateY(40%)
  `,
  )};
`;
