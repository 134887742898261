/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Image from 'components/Image';

const GracefulImage = ({
  imageData,
  alt,
  filename,
  styles = null,
  className = null,
  useDeprecatedImage = false,
}) => {
  const imageAlt = imageData?.data;
  const ImageComponent = useDeprecatedImage ? Image : GatsbyImage;
  return (
    <ImageComponent
      filename={filename}
      image={imageData?.gatsbyImageData}
      className={className}
      style={styles}
      alt={alt ? alt : imageAlt}
    />
  );
};

GracefulImage.propTypes = {
  filename: PropTypes.string,
  imageData: PropTypes.object,
  alt: PropTypes.string,
  styles: PropTypes.object,
  className: PropTypes.string,
  useDeprecatedImage: PropTypes.bool,
};

export default GracefulImage;
