import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/seo';
import SplitSectionWithImage from 'containers/SplitSectionWithImage';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SplitSectionWithImage
      imageName="common_all_pages/404.png"
      imageAlt="Page not found"
      isImageLeft={true}
    >
      <h2 className="text-center" data-testid="errortext" id="notFoundHeader">
        You just hit a route that doesn&#39;t exist... the sadness.
      </h2>
    </SplitSectionWithImage>
  </Layout>
);

export default NotFoundPage;
